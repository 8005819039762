import React, { useState } from 'react';
import Entry from './components/Entry';
import { Center, Flex, Text } from '@chakra-ui/react';
import { findSongs, findVocalists, getLeads } from './api/api-client';
import { type SongRhymingResult } from './models/song.model';
import Songs from './components/Songs';
import { type Vocalist } from './models/vocalist.model';
import Vocalists from './components/Vocalists';
import Offer from './components/Offer';
import ErrorMessage from './components/ErrorMessage';
import { type Lead } from './models/lead.model';
import BotLinks from './components/BotLinks';

type AppState = 'entry' | 'songs' | 'vocalists' | 'offer' | 'error';

const App = (): JSX.Element => {
  const [current, setCurrent] = useState<AppState>('entry');

  const [result, setResult] = useState<SongRhymingResult | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onPoemSubmit = (input: string): void => {
    setIsLoading(true);
    findSongs(input)
      .then((r) => {
        if (r === null) {
          setCurrent('error');
          return;
        }

        if (!r.success && r.chorus !== '') {
          setCurrent('error');
          return;
        }

        setResult(r);
        setIsLoading(false);

        if (r.success) {
          setCurrent('songs');
        }
      })
      .catch(() => {
        setCurrent('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [vocalists, setVocalists] = useState<Vocalist[]>([]);

  const onSongSelect = (id: number): void => {
    if (result === null) {
      return;
    }

    setIsLoading(true);
    findVocalists(result.id, id)
      .then((vocalists) => {
        setVocalists(vocalists);
        setCurrent('vocalists');
      })
      .catch(() => {
        setCurrent('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [leads, setLeads] = useState<Lead[] | null>(null);
  const onVocalSelect = (id: number): void => {
    if (result === null) {
      return;
    }

    setIsLoading(true);

    getLeads(result.id, id)
      .then((leads) => {
        setLeads(leads);
        setCurrent('offer');
      })
      .catch(() => {
        setCurrent('error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Center p="20px">
      <Flex flexDirection="column" w="100%" maxW="820px" gap="20px">
        {current === 'entry' && (
          <Entry submitPoem={onPoemSubmit} isLoading={isLoading} isError={!isLoading && result?.chorus === ''} />
        )}
        {current === 'songs' && result !== null && (
          <Songs chorus={result.chorus} songs={result.songs} onSongSelect={onSongSelect} isLoading={isLoading} />
        )}
        {current === 'vocalists' && (
          <Vocalists vocalists={vocalists} onVocalSelect={onVocalSelect} isLoading={isLoading} />
        )}
        {current === 'offer' && leads !== null && <Offer leads={leads} />}
        {result !== null && (current === 'songs' || current === 'vocalists' || current === 'offer') && (
          <BotLinks searchId={result.id} />
        )}
        {current === 'error' && <ErrorMessage />}
        <Text>{'© 2023 ООО «Мьюзик Рокет»'}</Text>
      </Flex>
    </Center>
  );
};

export default App;
