import React from 'react';
import { type Song } from '../../models/song.model';
import { Box, Text, VStack } from '@chakra-ui/react';
import SongCard from './SongCard';

interface SongsProps {
  chorus: string;
  songs: Song[];
  onSongSelect: (id: number) => void;
  isLoading: boolean;
}

const Songs = ({ chorus, songs, onSongSelect, isLoading }: SongsProps): JSX.Element => {
  return (
    <VStack align="flex-start" w="100%" gap="15px">
      <Text as="b">Ваш припев</Text>
      <Box>
        {chorus.split('\n').map((line, i) => (
          <Text key={i}>{line}</Text>
        ))}
      </Box>
      <VStack align="flex-start">
        <Text as="b">{`К припеву подобраны ${songs.length} песни`}</Text>
        <Text as="b">Выберите подходящую 👇🏻</Text>
      </VStack>
      <VStack align="flex-start" w="100%" gap="5px">
        {songs.map((s) => (
          <SongCard key={s.id} song={s} onSelect={onSongSelect} isLoading={isLoading} />
        ))}
      </VStack>
    </VStack>
  );
};

export default Songs;
