/* eslint-disable @typescript-eslint/return-await */
import axios from 'axios';
import { type SongRhymingResult } from '../models/song.model';
import { type Vocalist } from '../models/vocalist.model';
import { type Lead } from '../models/lead.model';

// const host = process.env.REACT_APP_API_URL;
const host = 'https://admin.musicrocket.ru/api';
const API = {
  findSongs: () => `${host}/rhyme/songs`,
  findVocalists: (searchId: number, songId: number) =>
    `${host}/rhyme/vocalists?search_id=${searchId}&song_id=${songId}`,
  getLeads: (searchId: number, vocalistId: number) =>
    `${host}/rhyme/leads?search_id=${searchId}&vocalist_id=${vocalistId}`,
};

export const findSongs = async (lyrics: string): Promise<SongRhymingResult | null> => {
  try {
    const response = await axios.post<SongRhymingResult>(API.findSongs(), { lyrics });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 400) {
      return { id: -1, success: false, chorus: '', songs: [] };
    }

    return null;
  } catch {
    return { id: -1, success: false, chorus: '', songs: [] };
  }
};

export const findVocalists = async (searchId: number, songId: number): Promise<Vocalist[]> => {
  try {
    const response = await axios.get<Vocalist[]>(API.findVocalists(searchId, songId));
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch {
    return [];
  }
};

export const getLeads = async (searchId: number, vocalistId: number): Promise<Lead[]> => {
  try {
    const response = await axios.get<Lead[]>(API.getLeads(searchId, vocalistId));
    if (response.status === 200) {
      return response.data;
    } else {
      return [];
    }
  } catch {
    return [];
  }
};
