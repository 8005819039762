import React from 'react';
import { Box } from '@chakra-ui/react';

interface VideoPreviewProps {
  link: string;
}

const VideoPreview = ({ link }: VideoPreviewProps): JSX.Element => {
  return (
    <Box w="100%" maxW="620px">
      <video controls width="100%" height="auto">
        <source src={`${link}#t=0.1`} type="video/mp4" />
      </video>{' '}
    </Box>
  );
};

export default VideoPreview;
