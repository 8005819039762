import { Button, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { type Lead } from '../models/lead.model';
import { Color } from './style.constants';
import './videobox.css';

interface OfferProps {
  leads: Lead[];
}

const Offer = ({ leads }: OfferProps): JSX.Element => {
  return (
    <VStack align="flex-start" w="100%" gap="25px" borderWidth={1} borderColor={Color.Gray} borderRadius={5} p="15px">
      <VStack align="flex-start" w="100%" gap="5px">
        <Text as="b">Пора узнать возьмется ли певец(ица)</Text>
        <Text>👇🏻 Узнайте, когда мы сможем приступить к вашей песне</Text>
        {leads.map(({ name, link, isMain }) => (
          <Link key={name} href={link} target="_blank">
            <Button colorScheme={isMain ? 'green' : 'blue'} variant={isMain ? 'solid' : 'outline'} w={280} mr={3}>
              {name}
            </Button>
          </Link>
        ))}
      </VStack>
      {/* <VStack align="flex-start" w="100%" gap="5px">
        <Text as="b">Звучите на всю Россию</Text>
        <Text>Из наших недавних результатов. Стихи поэта Вячеслава прозвучали в исполнении Марии у Малахова.</Text>
        <Text>Песня записана за 8 тр. Хотите так же?</Text>
        <Box w="100%" maxW="620px">
          <div className="thumb-wrap">
            <iframe
              src="https://vk.com/video_ext.php?oid=-24371598&id=456273877&hash=52a8a46b7e959690&hd=2"
              width="560"
              height="315"
              allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </Box>
      </VStack> */}
    </VStack>
  );
};

export default Offer;
