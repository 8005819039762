import React, { useState } from 'react';
import { type Song } from '../../models/song.model';
import { Box, Button, CircularProgress, CircularProgressLabel, Flex, Text, VStack } from '@chakra-ui/react';
import { Color } from '../style.constants';

interface SongCardProps {
  song: Song;
  onSelect: (id: number) => void;
  isLoading: boolean;
}

const SongCard = (props: SongCardProps): JSX.Element => {
  const {
    song: { id, author, text, accuracy, song: name },
    onSelect,
    isLoading,
  } = props;

  const [showSpinner, setShowSpinner] = useState(false);
  const handleClick = (): void => {
    onSelect(id);
    setShowSpinner(true);
  };

  const songTextSize = 4;

  return (
    <VStack borderWidth={1} borderColor={Color.Gray} borderRadius={5} p="15px" align="flex-start" w="100%" gap="20px">
      <Flex gap="15px">
        <CircularProgress value={accuracy} color="green.400">
          <CircularProgressLabel>{`${accuracy}%`}</CircularProgressLabel>
        </CircularProgress>
        <Box>
          <Text as="b">{name}</Text>
          <Text>{author}</Text>
        </Box>
      </Flex>
      <Box>
        {text
          .split('\n')
          .slice(0, songTextSize)
          .map((line, i) => (
            <Text key={i}>{line}</Text>
          ))}
      </Box>
      <Button
        isLoading={showSpinner}
        onClick={handleClick}
        size="md"
        colorScheme="green"
        isDisabled={isLoading}
        w={150}
        mt="15px"
      >
        Да, подходит
      </Button>
    </VStack>
  );
};

export default SongCard;
