import { Button, Flex, Text, Textarea, VStack, Image } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Color } from './style.constants';
import header from '../assets/top_for_hit.jpg';

interface EntryProps {
  submitPoem: (poem: string) => void;
  isLoading: boolean;
  isError: boolean;
}

const Entry = ({ submitPoem, isLoading, isError }: EntryProps): JSX.Element => {
  const [showError, setShowError] = useState(false);

  const [poem, setPoem] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setPoem(event.target.value);
    setShowError(false);
  };

  const onSubmit = (): void => {
    submitPoem(poem);
    setPoem('');
  };

  const isEmpty = poem.length === 0;

  useEffect(() => {
    if (isError && poem === '') {
      setShowError(isError);
    }
  }, [isError, poem]);

  return (
    <VStack align="flex-start" gap="20px">
      <Image src={header} borderRadius="10px" />
      <VStack align="flex-start">
        <Text as="b">ХОТИТЕ ОКАЗАТЬСЯ НА ТВ и РАДИО?</Text>
        <Text>Начнем. Вставьте только текст ваших стихов без заголовка ниже 👇🏻</Text>
        <Text>Нейросеть подберет к ним 3 известных песни, подходящих по настроению, а затем 2 подходящих вокала</Text>
      </VStack>
      <Flex alignSelf="stretch">
        <Textarea
          w="100%"
          h={250}
          value={poem}
          onChange={handleChange}
          borderWidth="2px"
          borderColor={Color.Some}
          borderStyle="solid"
          isInvalid={showError}
        />
      </Flex>
      {showError && (
        <Text size="xs" color="red">
          Ошибка. Вставьте только текст стихов с переносами строк. Без дополнительного текста
        </Text>
      )}
      <Button
        isLoading={isLoading}
        onClick={onSubmit}
        colorScheme="green"
        size="md"
        isDisabled={isLoading || isEmpty}
        w={150}
      >
        Отправить
      </Button>
    </VStack>
  );
};

export default Entry;
