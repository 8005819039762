import React from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { type Vocalist } from '../../models/vocalist.model';
import { Color } from '../style.constants';
import WaVideoPreview from './WaVideoPreview';

interface VocalistCardProps {
  vocalist: Vocalist;
  onSelect: (id: number) => void;
  isLoading: boolean;
}

const VocalistCard = (props: VocalistCardProps): JSX.Element => {
  const {
    vocalist: { id, name, description, waPreview },
    onSelect,
    isLoading,
  } = props;

  const handleClick = (): void => {
    onSelect(id);
  };

  return (
    <VStack borderWidth={1} borderColor={Color.Gray} borderRadius={5} p="15px" align="flex-start" w="100%" gap="5px">
      <VStack align="flex-start">
        <Text as="b">{name}</Text>
        <Text>{description}</Text>
      </VStack>
      {waPreview !== null && waPreview !== '' && <WaVideoPreview link={waPreview} />}
      <Button onClick={handleClick} size="md" colorScheme="green" isDisabled={isLoading} w={150}>
        Да, подходит
      </Button>
    </VStack>
  );
};

export default VocalistCard;
