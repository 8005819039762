import { VStack, Text } from '@chakra-ui/react';
import React from 'react';
import VocalistCard from './VocalistCard';
import { type Vocalist } from '../../models/vocalist.model';

interface VocalistsProps {
  vocalists: Vocalist[];
  onVocalSelect: (id: number) => void;
  isLoading: boolean;
}

const Vocalists = (props: VocalistsProps): JSX.Element => {
  const { vocalists, onVocalSelect, isLoading } = props;

  const onSelect = (id: number): void => {
    onVocalSelect(id);
  };

  return (
    <VStack align="flex-start" w="100%" gap="15px">
      <VStack align="flex-start" gap="5px">
        <Text as="b">{`Для вас подобраны ${vocalists.length} вокала`}</Text>
        <Text>👇🏻 Послушайте и выберите подходящий</Text>
      </VStack>
      <VStack align="flex-start" w="100%" gap="5px">
        {vocalists.map((v) => (
          <VocalistCard key={v.name} vocalist={v} onSelect={onSelect} isLoading={isLoading} />
        ))}
      </VStack>
    </VStack>
  );
};

export default Vocalists;
