import { Button, Link, Text, VStack, Image } from '@chakra-ui/react';
import React from 'react';
import { Color } from './style.constants';
import header from '../assets/top_for_hit.jpg';

const ErrorMessage = (): JSX.Element => {
  return (
    <VStack align="flex-start" w="100%" gap="20px" borderWidth={1} borderColor={Color.Gray} borderRadius={5} p="15px">
      <Image src={header} borderRadius="10px" />
      <VStack align="flex-start">
        <Text as="b">ХОТИТЕ ОКАЗАТЬСЯ НА ТВ и РАДИО?</Text>
        <Text>Начнем. Отправьте текст ваших стихов без заголовка в наш WhatsApp или ВК 👇🏻</Text>
        <Text>Нейросеть подберет к ним 3 известных песни, подходящих по настроению, а затем 2 подходящих вокала</Text>
      </VStack>
      <Link
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=78002500344&text=%D0%9F%D0%BE%D0%B4%D0%B1%D0%B5%D1%80%D0%B8%D1%82%D0%B5+%D1%85%D0%B8%D1%82%D1%8B+%D0%B8+%D0%B2%D0%BE%D0%BA%D0%B0%D0%BB%D1%8B+%D0%B4%D0%BB%D1%8F+%D0%BC%D0%BE%D0%B8%D1%85+%D1%81%D1%82%D0%B8%D1%85%D0%BE%D0%B2%0A%0A%D0%9F%D1%80%D0%BE%D0%BC%D0%BE%D0%BA%D0%BE%D0%B4%3A++h25"
      >
        <Button w="280px" colorScheme="green">
          Подбор в WhatsApp
        </Button>
      </Link>
      <Link target="_blank" href="https://vk.com/im?sel=-168517242">
        <Button w="280px" colorScheme="blue" variant="outline">
          Подбор в ВК
        </Button>
      </Link>
    </VStack>
  );
};

export default ErrorMessage;
